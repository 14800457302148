<template>
  <div v-if="userLoader.loading">
    <Loading />
  </div>
  <div v-if="userLoader.fulfill && userFound" id="home_wrap">
    <div class="section_header">
      <div class="person_info">
        <img id="person_avatar" :src="avatar" class="person_avatar" :alt="user.FullName" />
        <div class="person_info_txt">
          <div id="person_name" class="person_name">
            {{ user.FullName }}
          </div>
          <div v-if="deadline" id="person_deadline" class="person_deadline">
            會籍到期日: {{ deadline }}
          </div>
        </div>
      </div>
      <ul class="privilege_list">
        <router-link to="/coupon?page=coupon" class="privilege_list_item">
          <Badge :count="couponUnread">
            <img src="/static/images/icon/icon_coupon.png" alt="" />
            <div class="privilege_txt">優惠券</div>
          </Badge>
        </router-link>
        <router-link to="/record?page=record" class="privilege_list_item">
          <Badge :count="orderUnread">
            <img src="/static/images/icon/icon_record.png" alt="" />
            <div class="privilege_txt">消費紀錄</div>
          </Badge>
        </router-link>
        <router-link to="/my?page=my" class="privilege_list_item">
          <img src="/static/images/icon/icon_my.png" alt="" />
          <div class="privilege_txt">我的資料</div>
        </router-link>
      </ul>
    </div>
    <div class="section_card">
      <div class="card_wrap">
        <img id="member_card" :src="cardImage" class="member_card" alt="user.MemberTier" />
        <div :class="['card_info', 'bg-white']">
          <img id="barCode" :src="barCode" class="barCode" :alt="user.Mobile" />
          <div id="member_num" class="member_num">
            {{ memberNum }}
          </div>
        </div>
      </div>

      <div class="progress_info">
        已累積 NT${{ historyNum }}<br />
        <div v-if="hasNextTier">還差 NT${{ restNum }} 可升級{{ nextTier }}</div>
        <div v-if="!hasNextTier && maintainGoldAmount !== undefined">
          還差 NT${{ maintainGoldAmount.toLocaleString() }} 可維持金卡會員
        </div>
      </div>
      <img :src="grassImage" alt="tier-image" />
    </div>
    <ul class="section_footer">
      <router-link to="/qa?page=qa" class="footer_item">
        <img src="/static/images/icon/icon_qa.png" alt="" />
        <div class="privilege_txt">常見問題</div>
      </router-link>
      <router-link to="/rule?page=rule" class="footer_item">
        <img src="/static/images/icon/icon_memberRule.png" alt="" />
        <div class="privilege_txt">會員制度</div>
      </router-link>
      <a
        href="https://www.adidas.com.tw/V2/MyAccount/VipMemberBenefits"
        tag="li"
        class="footer_item"
      >
        <img src="/static/images/icon/icon_memberPolicy.png" alt="icon-member-policy" />
        <div class="privilege_txt">會員規範</div>
      </a>
    </ul>
  </div>
  <div :class="['tipPopup_wrap', { hide: !userTierExpiredModalOpen }]">
    <div class="tipPopup membership-expiry">
      <p style="font-size: 18px; margin-bottom: 18px">會籍到期日倒數 {{ daysLeft }}</p>
      <p style="font-size: 16px; margin-bottom: 12px">
        目前等級為 {{ memberTier }}，仍差 {{ maintainCurrentMemberTierAmount }} 元可續會 {{ memberTier }} 等級會籍
      </p>
      <p style="font-size: 12px">※ 會員優惠券與部分權益僅限銅卡以上等級會員獨享</p>
      <div class="tipPopup_cta">
        <a class="tipPopup_cta_button cancel_cta" @click="closeExpireModal()">返回</a>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import zhTW from 'dayjs/locale/zh-tw'
import relativeTime from 'dayjs/plugin/relativeTime'
import { onMounted } from 'vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

import Badge from '@/components/Badge.vue'
import Loading from '@/components/Loading.vue'

import useUpdateUser from '../composables/useUpdateUser'
import { MEMBER_MEMBER_NOT_FOUND, MEMBER_SUCCESS } from '../constants'
import func from '../js/func.js'
import { toBarcodeImage } from '../utils'

const  thresholds = [
  { l: 's', r: 1 },
  { l: 'm', r: 1 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 1 },
  { l: 'hh', r: 23, d: 'hour' },
  { l: 'd', r: 1 },
  { l: 'dd', r: 29, d: 'day' },
  { l: 'M', r: 1 },
  { l: 'MM', r: 11, d: 'month' },
  { l: 'y', r: 1 },
  { l: 'yy', d: 'year' }
]

dayjs.extend(relativeTime, { thresholds })
zhTW.relativeTime.future = '%s'
dayjs.locale(zhTW)

export default {
  name: 'HomeView',
  components: {
    Badge,
    Loading
  },
  setup() {
    const { checkEnv } = func()
    const store = useStore()
    const closeExpireModal = () => store.dispatch("setUserTierExpiredModal", false);
    const userLoader = computed(() => store.state.user)

    useUpdateUser()

    onMounted(() => {
      //檢查是否是在line環境打開頁面
      checkEnv()
    })

    return {
      userLoader,
      closeExpireModal,
      userTierExpiredModalOpen: computed(() => store.state.userTierExpiredModalOpen),
      user: computed(() => userLoader.value.data),
      userFound: computed(() => userLoader.value.data?.ResultCode === MEMBER_SUCCESS),
      userNotFound: computed(() => userLoader.value.data?.ResultCode === MEMBER_MEMBER_NOT_FOUND),
      couponUnread: computed(() => userLoader.value.data?.CouponUnread ?? 0),
      orderUnread: computed(() => userLoader.value.data?.OrderUnread ?? 0),
      deadline: computed(() => userLoader.value.data?.MemberTierExpiration ?? ''),
      daysLeft: computed(() => {
        if (!userLoader.value.fulfill || !userLoader.value.data || !userLoader.value.data.MemberTierExpiration) {
          return NaN;
        }

        const dateToExpire = dayjs(userLoader.value.data.MemberTierExpiration)

        return dateToExpire.fromNow()
      }),
      memberTier: computed(() => userLoader.value.data?.MemberTier ?? ''),
      memberNum: computed(() => {
        if (!userLoader.value.data || !userLoader.value.fulfill) {
          return ''
        }
        const { MemberCode, Mobile } = userLoader.value.data
        return MemberCode ? MemberCode : Mobile
      }),
      historyNum: computed(() =>
        Number(userLoader.value.data?.CumulativeAmount ?? 0).toLocaleString()
      ),
      cardImage: computed(() => {
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        switch (cardLevel) {
          case '普卡会员':
          case '普卡會員':
            return '/static/images/card_default.png'
          case '铜卡会员':
          case '銅卡會員':
            return '/static/images/card_copper.png'
          case '银卡会员':
          case '銀卡會員':
            return '/static/images/card_silver.png'
          case '金卡会员':
          case '金卡會員':
            return '/static/images/card_gold.png'
          default:
            return '/static/images/card_default.png'
        }
      }),
      grassImage: computed(() => {
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        switch (cardLevel) {
          case '普卡会员':
          case '普卡會員':
            return '/static/images/grass_default.png'
          case '铜卡会员':
          case '銅卡會員':
            return '/static/images/grass_copper.png'
          case '银卡会员':
          case '銀卡會員':
            return '/static/images/grass_silver.png'
          case '金卡会员':
          case '金卡會員':
            return '/static/images/grass_gold.png'
          default:
            return '/static/images/grass_default.png'
        }
      }),
      isDefaultLevel: computed(() => {
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        return cardLevel === '普卡會員' || cardLevel === '普卡会员'
      }),
      hasNextTier: computed(() => {
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        return cardLevel !== '金卡會員' && cardLevel !== '金卡会员'
      }),
      maintainGoldAmount: computed(() => {
        return userLoader.value.data?.MaintainGoldAmount
      }),
      nextTier: computed(() => {
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        switch (cardLevel) {
          case '普卡会员':
          case '普卡會員':
            return '銅卡會員'
          case '铜卡会员':
          case '銅卡會員':
            return '銀卡會員'
          case '银卡会员':
          case '銀卡會員':
            return '金卡會員'
          case '金卡会员':
          case '金卡會員':
            return ''
          default:
            return ''
        }
      }),
      restNum: computed(() => {
        const sum = userLoader.value.data?.CumulativeAmount ?? 0
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        let result = 0
        switch (cardLevel) {
          case '普卡会员':
          case '普卡會員':
            result = 8000 - sum
            break
          case '铜卡会员':
          case '銅卡會員':
            result = 18000 - sum
            break
          case '银卡会员':
          case '銀卡會員':
            result = 38000 - sum
            break
        }
        return result.toLocaleString()
      }),
      maintainCurrentMemberTierAmount: computed(() => {
        const sum = userLoader.value.data?.CumulativeAmount ?? 0
        const cardLevel = userLoader.value.data?.MemberTier ?? ''
        let result = 0
        switch (cardLevel) {
          case '普卡会员':
          case '普卡會員':
            result = 0
            break
          case '铜卡会员':
          case '銅卡會員':
            result = 5000 - sum
            break
          case '银卡会员':
          case '銀卡會員':
            result = 15000 - sum
            break
          case '金卡会员':
          case '金卡會員':
            result = 30000 - sum
            break
        }
        return result.toLocaleString()
      }),
      barCode: computed(() => {
        if (!userLoader.value.data || !userLoader.value.fulfill) {
          return ''
        }
        const { MemberCode, Mobile } = userLoader.value.data
        return toBarcodeImage(MemberCode ?? Mobile, false)
      }),
      avatar: computed(() =>
        userLoader.value.data?.PictureUrl
          ? userLoader.value.data?.PictureUrl
          : '/static/images/avatar.png'
      )
    }
  }
}
</script>

<style scoped></style>
