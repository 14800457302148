<template>
  <span class="badge">
    <slot />
    <sup class="badge-count" :title="count" v-if="count > 0">
      <bdi>
        <span class="number-only">
          <span class="number-only-unit current">{{ count }}</span>
        </span>
      </bdi>
    </sup>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NumberBadge',
  props: {
    count: {
      type: Number,
      required: true
    }
  }
})
</script>

<style>
.badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: fit-content;
}

.badge-count {
  position: absolute;
  top: -6px;
  right: -8px;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  min-width: 16px;
  padding: 2px 4px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #ff4d4f;
  border-radius: 16px;
  box-shadow: 0 0 0 1px #fff;
}
</style>
