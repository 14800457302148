const API_ORIGIN = import.meta.env["VITE_API_ORIGIN"]
const LIFF_ID = import.meta.env["VITE_LIFF_ID"]
const BOT_ID = import.meta.env["VITE_BOT_ID"]
const AUTH_API_PATH = import.meta.env["VITE_AUTH_API_PATH"]

export {
  API_ORIGIN,
  AUTH_API_PATH,
  BOT_ID,
  LIFF_ID,
}