import objectInspect from "object-inspect";

export function inspectMessage(
  strArr,
  ...args
) {
  return ["", ...strArr].reduce(
    (previousValue, currentValue, currentIndex) =>
      previousValue +
      currentValue +
      (currentIndex - 1 in args ? objectInspect(args[currentIndex - 1]) : "")
  );
}
