export const camelToSnake = (str) =>
  str[0].toLowerCase() +
  str
    .slice(1, str.length)
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Return an object with snaked-cased keys
 */
export const camelCaseKeysToSnake = (obj) =>
  Object.keys(obj).reduce((prev, curr) => {
    prev[camelToSnake(curr)] = obj[curr];
    return prev;
  }, {});
