<template>
  <navigation />
  <div v-if="userLoader.loading">
    <Loading />
  </div>
  <div v-if="userLoader.fulfill" class="section_person">
    <div class="person_profile">
      <div class="avatar_warp">
        <img :src="user.PictureUrl" class="profile_avatar" alt="" />
      </div>
      <div class="person_name">
        {{ user.FullName }}
      </div>
    </div>
    <ul class="personInfo_list">
      <li>
        <div class="listProp">手機號碼</div>
        <div class="listVal">
          {{ user.Mobile }}
        </div>
      </li>
      <li>
        <div class="listProp">會員性別</div>
        <div class="listVal">
          {{ user.GenderCode }}
        </div>
      </li>
      <li>
        <div class="listProp">出生日期</div>
        <div class="listVal">
          {{ user.BirthDate }}
        </div>
      </li>
      <li>
        <div class="listProp">通訊地址</div>
        <div class="listVal">
          {{ user.DetailAddress }}
        </div>
      </li>
      <li>
        <div class="listProp">Email</div>
        <div class="listVal">
          {{ user.Email }}
        </div>
      </li>
      <li>
        <div class="listProp">最近消費日</div>
        <div class="listVal">
          {{ user.LastBuyTime }}
        </div>
      </li>
    </ul>
    <div class="change_cta_wrap">
      <a href="https://www.adidas.com.tw/V2/VipMember/Profile" class="change_cta"> 修改會員資料 </a>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

import Loading from '@/components/Loading.vue'

import navigation from '../components/navigation.vue'
import useUpdateUser from '../composables/useUpdateUser'
import { GENDER_MAN, UI_DATE_FORMAT_SHORT } from '../constants'

const formatToUI = (user) => {
  return {
    ...user,
    PictureUrl: user.PictureUrl ? user.PictureUrl : '/static/images/person_avatar.png',
    GenderCode: user.GenderCode === GENDER_MAN ? '男性' : '女性',
    BirthDate: dayjs(user.BirthDate).format(UI_DATE_FORMAT_SHORT),
    LastBuyTime:
      user.LastBuyTime !== '1900-01-01T00:00:00'
        ? dayjs(user.LastBuyTime).format(UI_DATE_FORMAT_SHORT)
        : '無'
  }
}

export default defineComponent({
  name: 'ProfileView',
  components: { navigation, Loading },
  setup() {
    const store = useStore()
    const userLoader = computed(() => store.state.user)

    const user = computed(() => {
      const data = store.state.user.data
      if (data === null) {
        return data
      }
      return formatToUI(data)
    })

    useUpdateUser()

    return { userLoader, user }
  }
})
</script>

<style scoped></style>
