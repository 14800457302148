// 門店交易
export const ORDER_TYPE_OWN_RETAIL = 100000000;
// 官網交易
export const ORDER_TYPE_OFFICIAL_WEBSITE = 100000001;
// 特許經銷
export const ORDER_TYPE_FRANCHISED_DISTRIBUTOR = 100000002;
// 全部
export const ORDER_TYPE_ALL = 100000004;

// 未使用
export const COUPON_STATE_UNUSED = 0;
// 已使用
export const COUPON_STATE_USED = 1;
// 已過期
export const COUPON_STATE_EXPIRED = 2;
// 已預用
export const COUPON_STATE_PRE_USED = 3;
// 所有狀態
export const COUPON_STATE_ALL = 4;

export const COUPON_ISSUE_CHANNEL_ALL = 100000000;
// 發放渠道 - 線上
export const COUPON_ISSUE_CHANNEL_ONLINE_ONLY = 100000001;
// 發放渠道 - 線下
export const COUPON_ISSUE_CHANNEL_OFFLINE_ONLY = 100000002;
// 未授權存取
export const MEMBER_UNAUTHORIZED_ACCESS = "-2";
// 異常訊息
export const MEMBER_UNEXPECTED = "-1";
// 查詢成功
export const MEMBER_SUCCESS = "0";
// 查詢不到會員
export const MEMBER_MEMBER_NOT_FOUND = "1";
// 無效參數
export const MEMBER_BAD_REQUEST = "2";
// 會員帳號異常
export const MEMBER_ABNORMAL_MEMBER_ACCOUNT = "3";

//# region result code
/**
 * 未授權存取
 * @type {string}
 */
export const RESULT_CODE_UNAUTHORIZED_ACCESS = "-2";
/**
 * 異常訊息
 * @type {string}
 */
export const RESULT_CODE_UNEXPECTED = "-1";
/**
 * 成功
 * @type {string}
 */
export const RESULT_CODE_SUCCESS = "0";
/**
 * 失敗
 * @type {string}
 */
export const RESULT_CODE_FAIL = "1";
/**
 * 無效參數
 * @type {string}
 */
export const RESULT_CODE_BAD_REQUEST = "2";
//# endregion

//# region 優惠券兌換
/**
 * 券預使用
 * @type {number}
 */
export const COUPON_UPDATE_TYPE_PRE_USED = 1;
/**
 * 券取消使用
 * @type {number}
 */
export const COUPON_UPDATE_TYPE_CANCEL = 2;
/**
 * 券號交易單號關聯
 * @type {number}
 */
export const COUPON_UPDATE_TYPE_BIND_TO_ORDER = 3;
//# endregion

// 公司成立日期
export const COMPANY_FOUNDED_AT = "1949-08-18";
// Page Counts
export const DEFAULT_PAGES = 9999;
// Default error message
export const ERROR_MESSAGE = "系統忙碌中，請稍後再嘗試";
// Django API format
export const API_DATE_FORMAT = "YYYY-MM-DD";
export const UI_DATE_FORMAT_SHORT = "YYYY/M/D";
export const UI_DATE_FORMAT_ZH_TW = "YYYY年M月D日";

export const GENDER_MAN = 1;

export const GENDER_WOMAN = 2;

export const DEVELOPMENT_MODE = import.meta.env.DEV;

export const CONTACT_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSdw2JR6x2tMl9Ky61JQvFFr2cyv2vL14T4_zESY7otUxsI15Q/viewform";
