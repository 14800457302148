import liff from "@line/liff";
import dayjs from "dayjs";
import delay from "lodash.delay";
import Swal from "sweetalert2";
import { onMounted } from "vue";
import { useStore } from "vuex";

import {
  DEVELOPMENT_MODE,
  ERROR_MESSAGE,
  MEMBER_MEMBER_NOT_FOUND,
  MEMBER_SUCCESS,
} from "@/constants";

import fakeProfile from "../../__mocks__/getProfile.json";
import { getMember, setupAuthentication } from "../services";

const message = (text) => {
  return Swal.fire({
    html: `<span style="font-size:14pt">${text}</span>`,
    confirmButtonColor: "#3e6be2",
    confirmButtonText: "我知道了",
  });
};

const getProfile = async () => {
  if (DEVELOPMENT_MODE) {
    return new Promise((resolve) => delay(() => resolve(fakeProfile), 500));
  }
  return liff.getProfile();
};

const getMaintainCurrentMemberTierAmount = (tier, cumulativeAmount) => {
  switch(tier) {
    case '普卡会员':
    case '普卡會員':
      return 0
    case '铜卡会员':
    case '銅卡會員':
      return 5000 - cumulativeAmount
    case '银卡会员':
    case '銀卡會員':
      return 15000 - cumulativeAmount
    case '金卡会员':
    case '金卡會員':
      return 30000 - cumulativeAmount
    default:
      return 0
  }
}

export default function useUser() {
  const store = useStore();
  const updateUser = (payload) => store.dispatch("updateUser", payload);
  const openExpireModal = () => store.dispatch("setUserTierExpiredModal", true);

  onMounted(async () => {
    try {
      await liff.ready;
      const token = await setupAuthentication();
      if (!DEVELOPMENT_MODE && token.length === 0) return;

      const [data, profile] = await Promise.all([getMember(), getProfile()]);

      if (data?.ResultCode !== MEMBER_SUCCESS) {
        let errorMessage = ERROR_MESSAGE;
        if (data?.ResultCode === MEMBER_MEMBER_NOT_FOUND) {
          errorMessage = "查無會員資料";
        }

        message(errorMessage).then(liff.closeWindow);
        return;
      }

      updateUser({
        user: {
          data: {
            ...data,
            PictureUrl: profile.pictureUrl,
          },
          loading: false,
          fulfill: true,
        },
      });

      if (!data.MemberTierExpiration || data.MemberTier === "普卡會員") return;

      const deadline = dayjs(data.MemberTierExpiration)
      
      if (deadline.diff(dayjs()) <= 0 || deadline.diff(dayjs(), 'day') > 30) return

      if (getMaintainCurrentMemberTierAmount(data.MemberTier, data.CumulativeAmount) <= 0) return

      openExpireModal()
    } catch (error) {
      updateUser({ user: { loading: false, reject: true } });

      let errorMessage = ERROR_MESSAGE;
      if (error.isAxiosError && error.response?.status === 403) {
        errorMessage = "請先登入或註冊成為會員";
      }
      message(errorMessage).then(liff.closeWindow);
    }
  });
}
