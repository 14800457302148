<template>
  <router-view />
  <div id="err_tips" class="hide">
    <div class="tips_wrap">
      <div class="tips_title">提 示</div>
      <div class="tips_txt">請在Line APP裡打開會員頁</div>
    </div>
  </div>
</template>

<script>
import liff from '@line/liff'
import Swal from 'sweetalert2'
import { onMounted } from 'vue'

import { LIFF_ID } from '@/env'

import setupFacade from './js/func.js'
import { logError } from './logger/sentry.js'

export default {
  name: 'App',
  async created() {
    try {
      await liff.init({ liffId: LIFF_ID })
    } catch (error) {
      logError(error)
      await Swal.fire({
        icon: 'error',
        title: '<h2 data-testid="errorTitle">LIFF 發生錯誤，請稍後再嘗試</h2>',
        html: `<span data-testid="errorMsg">${error.message}</span>`,
        confirmButtonColor: '#3e6be2',
        confirmButtonText: '我知道了'
      })
      liff.closeWindow()
    }
  },
  setup() {
    onMounted(() => {
      setupFacade().setSize()
    })

    return {}
  }
}
</script>

<style scoped></style>
