import $ from "jquery";
import { useRoute, useRouter } from "vue-router";

import { CONTACT_LINK } from "@/constants";

export default function func() {
  const useRouterMethod = useRouter();

  function banScale() {
    let lastTouchEnd = 0;
    document.addEventListener("touchstart", (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
    document.addEventListener(
      "touchend",
      (event) => {
        const now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );

    // 阻止雙指放大
    document.addEventListener("gesturestart", (event) => {
      event.preventDefault();
    });
  }

  // 檢查是否是在line環境打開頁面
  function checkEnv() {
    // if (!DEVELOPMENT_MODE) {
    //   const ua = navigator.userAgent.toLowerCase();
    //   if (ua.indexOf("line") === -1) {
    //     $("#home_wrap").empty();
    //     $("#err_tips").removeClass("hide");
    //   }
    // }
  }

  // 設置網頁默認字體大小
  function setSize() {
    const maxWidth1 = 1200;
    const maxWidth2 = 750;
    let fontSize;
    const windowWidth = parseInt($(window).width());
    const windowHeight = parseInt($(window).height());

    if (windowWidth >= maxWidth1) {
      fontSize = 24;
    } else if (windowWidth > maxWidth2 && windowWidth < maxWidth1) {
      fontSize = ($(window).width() / maxWidth1) * 28;
    } else if (
      windowWidth <= maxWidth2 &&
      windowWidth / windowHeight >= 513 / 760
    ) {
      fontSize = ($(window).width() / maxWidth2) * 25 * (750 / 815);
    } else if (windowWidth <= maxWidth2) {
      fontSize = ($(window).width() / maxWidth2) * 25;
    }

    $("html,body").css({ "font-size": fontSize + "px" });
    banScale();
  }

  // 根據query顯示導航欄標題
  function gup() {
    const {
      query: { page },
    } = useRoute();

    window.route = useRoute();
    window.router = useRouter();

    switch (page) {
      case "coupon":
        return "優惠券";
      case "record":
        return "消費記錄";
      case "my":
        return "我的資料";
      case "qa":
        return "常見問題";
      case "rule":
        return "會員制度";
      case "policy":
        return "會員規範";
      default:
        return "";
    }
  }

  function goBack() {
    useRouterMethod.go(-1);
  }

  function tabClick() {
    $(".tab_item").click(function () {
      const index = $(this).index();

      $(".tab_item,.coupon_list").removeClass("active");
      $(this).addClass("active");
      $(".coupon_list").eq(index).addClass("active");
    });
  }

  function qaClick() {
    $(".list_menu").click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active").find(".qa_arrow").removeClass("rotate");
      } else {
        $(".list_menu").removeClass("active");
        $(".qa_arrow").removeClass("rotate");
        $(this).addClass("active").find(".qa_arrow").addClass("rotate");
      }
    });
  }

  const answerData = {
    Q1: {
      Q: "我要如何加入adiclub會員?",
      A: "您可透過adidas官網 (<a href='https://www.adidas.com.tw/'>https://www.adidas.com.tw/</a>) 或 adidas Taiwan LINE 官方帳號免費註冊成為會員。",
    },
    Q3: {
      Q: "我要如何查詢我的會員等級、累積消費、優惠券?",
      A: "您可透過adidas官網 (<a href='https://www.adidas.com.tw/'>https://www.adidas.com.tw/</a>) 或 adidas Taiwan LINE 登入會員後，進入會員中心查詢。",
    },
    Q4: {
      Q: "為何我在特定門市的消費沒有加入累計消費金額?",
      A: "目前會員制度僅限於adidas直營門市，直營門市清單請見：<a href='https://www.adidas.com.tw/Article/Detail/82536'>https://www.adidas.com.tw/Article/Detail/82536</a>",
    },
    Q5: {
      Q: "我的交易訂單何時會成為累計消費金額?",
      A: "1) 在adidas官方購物網站累積消費金額僅計算近12個月內之訂單實付金額(不含運費並無取消或退貨)，金額會在交易完成後第15天列入計算。<br>2) 在adidas直營門市累積消費金額僅計算近12個月內之訂單實付金額，金額會在交易完成後第二個工作天列入計算。",
    },
    Q6: {
      Q: "在會員中心的累積消費金額，為何沒有包含我全部的交易?",
      A: `累積消費金額僅計算近12個月內之訂單實付金額，官網購物之金額會在交易完成後第15天列入計算，在直營門市購物之金額會在交易完成後第二個工作天列入計算；若您在交易完成後 15 天仍未看到完整的累積消費金額，請與我們的<a href='${CONTACT_LINK}' target='_blank'>客服聯繫</a>。`,
    },
    Q7: {
      Q: "已達累積消費金額，何時可以升級會員?",
      A: "您在官網購物之金額會在交易完成後第15天列入計算，在直營門市購物之金額會在交易完成後第二個工作天列入計算，若您的累積消費金額已達門檻，我們會在達成門檻次日升級您的會員等級。",
    },
    Q8: {
      Q: "門市與官網的消費金額是一起累計嗎?",
      A: "是，直營門市與官網的消費金額可同步累計，直營門市清單請見：<a href='https://www.adidas.com.tw/Article/Detail/82536'>https://www.adidas.com.tw/Article/Detail/82536</a>",
    },
    Q9: {
      Q: "為何我在特定門市不能使用優惠券?",
      A: "目前優惠券僅適用於adidas直營門市(部分優惠券暢貨中心不適用)，詳細清單請見：<a href='https://www.adidas.com.tw/Article/Detail/82536'>https://www.adidas.com.tw/Article/Detail/82536</a>",
    },
    Q10: {
      Q: "優惠券何時發送(購物優惠券/升級優惠券/生日優惠券)?",
      A: "1) 購物優惠券將在達成門檻的次日發送(官網為交易完成後的第15天，門市為交易完成後次日)；<br>2) 升級優惠券將在達成升級門檻的次日發送；<br>3) 生日優惠券將在會員生日當月的1號發送，若您在生日當月成為會員，生日優惠券會遞延至次月一號發送。",
    },
    Q11: {
      Q: "我的優惠券為何無法使用?",
      A: "每張優惠券都有對應的面額、門檻與期限，您可至會員中心查詢該優惠券的設定；此外，暢貨中心(Outlet)與部分指定商品將不適用優惠券。",
    },
    Q12: {
      Q: "同個訂單可否使用多張優惠券?",
      A: "除入會優惠券不得與其他優惠券同時使用之外，其他adiclub優惠券可同時使用。唯不論單獨使用或疊加使用，該筆訂單之付款總額不得低於購買商品總訂價之50%。 (其他行銷活動發放之優惠券，以屆時公布的使用規則為主。)",
    },
    Q13: {
      Q: "退貨時，優惠券會如何處理?",
      A: "若會員在adidas官網使用adiclub優惠券於同一訂單中購買多個商品，該筆訂單不接受部分商品退貨，僅接受整筆訂單所有商品同時退貨，使用優惠券折抵的金額將不會返還，已使用的adiclub優惠券將不會以任何形式退還到會員帳號。",
    },
    Q14: {
      Q: "如有其他adiclub 會員問題，該如何解決?",
      A: `adiclub 相關的問題 (包含會員等級、累積消費、優惠券等) ，請與我們的<a href='${CONTACT_LINK}' target='_blank'>客服聯繫</a>。`,
    },
  };

  function showQA() {
    const {
      query: { item },
    } = useRoute();
    return answerData[item];
  }

  return {
    setSize,
    gup,
    goBack,
    tabClick,
    qaClick,
    showQA,
    checkEnv,
  };
}
