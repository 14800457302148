<template>
  <div class="top_navigation">
    <img src="/static/images/nav_arrow.png" class="nav_arrow" alt="" @click="goBack" />
    <div class="nav_txt">
      {{ gup() }}
    </div>
    <img src="/static/images/nav_logo.png" class="nav_logo" alt="" />
  </div>
</template>

<script>
import func from '../js/func.js'

export default {
  name: 'NavigationBar',
  setup() {
    const { gup, goBack } = func()

    return { gup, goBack }
  }
}
</script>

<style scoped></style>
