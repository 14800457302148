import axios from "axios";

import { AUTH_API_PATH } from '@/env'

import { camelCaseKeysToSnake } from "./camelToSnake";

/**
 * Get application token, for more details see https://github.com/chatbotgang/line_liff_auth#api-usage
 * @param liffAccessToken access token from LIFF SDK method `liff.getAccessToken`
 * @param data other optional data
 * @param data.liffId optional LIFF ID
 */
export const getAuthToken = async (liffAccessToken, data) => {
  const reqData = data
    ? {
      access_token: liffAccessToken,
      data: camelCaseKeysToSnake(data),
    }
    : {
      access_token: liffAccessToken,
    };

  // { token: string }
  const resp = await axios
    .post(AUTH_API_PATH, reqData, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((resp) => resp.data);

  return resp.token;
};
