import {
  captureException,
  captureMessage,
} from "@sentry/vue";

import { inspectMessage } from "@/utilities/inspectMessage";

export function logError(error) {
  if (import.meta.env.DEV && import.meta.env["NODE_ENV"] !== "test") {
    console.error("[logError]", error);
  }

  if (error instanceof Error) {
    return captureException(error);
  }

  return captureMessage(inspectMessage`Caught a non-error thrown: ${error}`);
}
