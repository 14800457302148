import liff from "@line/liff";
import { onMounted } from "vue";
import { useStore } from "vuex";

import { getOrders, setupAuthentication } from "../services";

export default function useUpdateOrders() {
  const store = useStore();
  const updateOrders = (payload) => store.dispatch("updateOrders", payload);

  onMounted(async () => {
    try {
      await liff.ready;
      await setupAuthentication();

      const data = await getOrders();
      updateOrders({ orders: { data, loading: false, fulfill: true } });
    } catch (error) {
      updateOrders({ orders: { loading: false, reject: true } });
    }
  });
}
