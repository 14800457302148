import { createRouter,createWebHistory } from "vue-router";

import coupon from "../views/coupon.vue";
import home from "../views/home.vue";
import my from "../views/my.vue";
import noqa from "../views/noqa.vue";
import qa from "../views/qa.vue";
import record from "../views/record.vue";
import rule from "../views/rule.vue";

const history = createWebHistory();

const router = createRouter({
  history,
  routes: [
    {
      path: "/",
      component: home,
    },
    {
      path: "/coupon",
      component: coupon,
    },
    {
      path: "/record",
      component: record,
    },
    {
      path: "/my",
      component: my,
    },
    {
      path: "/qa",
      component: qa,
    },
    {
      path: "/noqa",
      component: noqa,
    },
    {
      path: "/rule",
      component: rule,
    },
  ],
});

export default router;
