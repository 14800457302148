<template>
  <navigation />
  <div class="section_rule">
    <table class="rule_table">
      <tr>
        <td class="row_first_cell">會員等級</td>
        <td class="darkCell">
          普卡會員
          <img class="rule_card" src="/static/images/card_default.png" alt="" />
        </td>
        <td class="darkCell">
          銅卡會員
          <img class="rule_card" src="/static/images/card_copper.png" alt="" />
        </td>
        <td class="darkCell">
          銀卡會員
          <img class="rule_card" src="/static/images/card_silver.png" alt="" />
        </td>
        <td class="darkCell">
          金卡會員
          <img class="rule_card" src="/static/images/card_gold.png" alt="" />
        </td>
      </tr>
      <tr>
        <td class="row_first_cell">會員資格</td>
        <td>完成註冊</td>
        <td>單筆滿$5,000<br /><br />12個月內累積$8,000</td>
        <td>單筆滿$15,000<br /><br />12個月內累積$18,000</td>
        <td>單筆滿$30,000<br /><br />12個月內累積$38,000</td>
      </tr>
      <tr>
        <td class="row_first_cell">續會資格</td>
        <td>-</td>
        <td>12個月內累積$5,000</td>
        <td>12個月內累積$15,000</td>
        <td>12個月內累積$30,000</td>
      </tr>
      <tr>
        <td class="row_first_cell">入會優惠券</td>
        <td colspan="4">$200 優惠券 1 張</td>
      </tr>
      <tr>
        <td class="row_first_cell">購物優惠券</td>
        <td>-</td>
        <td colspan="3">
          單筆滿$3,000獲$300優惠卷1張<br />
          （$6,000送$300優惠券2張，以此類推無上限）<br />
          *特殊商品除外
        </td>
      </tr>
      <tr>
        <td class="row_first_cell">生日優惠券</td>
        <td>-</td>
        <td>$300</td>
        <td>$600</td>
        <td>$1,200</td>
      </tr>
      <tr>
        <td class="row_first_cell">升級優惠券</td>
        <td>-</td>
        <td>$300</td>
        <td>$600</td>
        <td>$1,200</td>
      </tr>
      <tr>
        <td class="row_first_cell">續會優惠券</td>
        <td>-</td>
        <td>$300</td>
        <td>$600</td>
        <td>$1,200</td>
      </tr>
      <tr>
        <td class="row_first_cell">新品情報與活動通知</td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
      </tr>
      <tr>
        <td class="row_first_cell">參與特定行銷<br />折扣活動</td>
        <td>-</td>
        <td>-</td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
      </tr>
      <tr>
        <td class="row_first_cell">部分限量商品<br />購買投籤資格</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
      </tr>
      <tr>
        <td class="row_first_cell">專屬會員禮</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>
          <img class="rule_circle" src="/static/images/icon/rule_circle.png" alt="" />
        </td>
      </tr>
    </table>

    <div class="rule_cta_wrap">
      <!--<a href="https://www.adidas.com.tw/login" class="rule_cta join_cta">立即加入</a><br>-->
      <a
        href="https://www.adidas.com.tw/V2/MyAccount/VipMemberBenefits"
        class="rule_cta learn_more"
      >
        了解會員規範
      </a>
    </div>
  </div>
</template>

<script>
import navigation from '../components/navigation.vue'

export default {
  name: 'RuleView',
  components: { navigation }
}
</script>

<style scoped></style>
