import liff from "@line/liff";
import axios from "axios";
import dayjs from "dayjs";

import { API_ORIGIN, BOT_ID } from '@/env'

import {
  API_DATE_FORMAT,
  COMPANY_FOUNDED_AT,
  COUPON_STATE_ALL,
  DEFAULT_PAGES,
  DEVELOPMENT_MODE,
  ORDER_TYPE_ALL,
} from "./constants";
import { getAuthToken } from "./utilities";

const axiosApi = axios.create();
const API_ROOT = `${API_ORIGIN}/adidas`;
const MemberAPI = `${API_ROOT}/v1/member`;
const foundedAt = dayjs(COMPANY_FOUNDED_AT);

export const setupAuthentication = async () => {
  if (DEVELOPMENT_MODE) return "";
  if (!liff.isLoggedIn()) {
    liff.login({
      redirectUri: window.location.href,
    });
    return "";
  }
  const accessToken = liff.getAccessToken() ?? "";
  const token = await getAuthToken(accessToken);
  setAuthHeader(token);
  return token;
};

export const setAuthHeader = (token) => {
  axiosApi.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getMember = async () => {
  const botId = BOT_ID;

  const resp = await axiosApi.get(`${MemberAPI}/detail/`, {
    params: {
      bot_id: botId,
    },
  });
  return resp.data;
};

export const getCoupons = async (params = {}) => {
  const botId = BOT_ID;

  const {
    state = COUPON_STATE_ALL,
    beginIndex = 1,
    pageCount = DEFAULT_PAGES,
  } = params;

  const resp = await axiosApi.get(`${MemberAPI}/coupons/`, {
    params: {
      bot_id: botId,
      state: state,
      begin_index: beginIndex,
      page_count: pageCount,
    },
  });
  return resp.data;
};

export const updateCoupon = async (params = {}) => {
  const botId = BOT_ID;

  const { couponId, updateType } = params;

  const resp = await axiosApi.post(`${MemberAPI}/update_coupon/`, {
    bot_id: botId,
    coupon_ids: [couponId],
    update_type: updateType,
  });
  return resp.data;
};

export const getOrders = async (params = {}) => {
  const botId = BOT_ID;

  const today = dayjs();

  const {
    orderType = ORDER_TYPE_ALL,
    beginTime = foundedAt,
    endTime = today,
    beginIndex = 1,
    pageCount = DEFAULT_PAGES,
  } = params;

  const resp = await axiosApi.get(`${MemberAPI}/orders/`, {
    params: {
      bot_id: botId,
      order_type: orderType,
      begin_time: beginTime.format(API_DATE_FORMAT),
      end_time: endTime.format(API_DATE_FORMAT),
      begin_index: beginIndex,
      page_count: pageCount,
    },
  });
  return resp.data;
};