import dayjs from "dayjs";

import { API_ORIGIN } from "./env";

export const toBarcodeImage = (text, transparent = false) => {
  let path = new URL(`${API_ORIGIN}/barcode/${text}`);
  if (transparent) {
    path.searchParams.set("mode", "RGBA");
    path.searchParams.set("background", "rgba(0,0,0,0)");
  }
  return path.toString();
};

export const formatDate = (ds, format = "YYYY/MM/DD") =>
  dayjs(ds).format(format);
