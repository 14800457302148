<template>
  <navigation />
  <div class="section_none">
    <div class="notFound">
      {{ showQA().Q }}
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div class="notFound_tip" v-html="showQA().A" />
    <!--eslint-enable-->
  </div>
</template>

<script>
import navigation from '../components/navigation.vue'
import func from '../js/func.js'

export default {
  name: 'NoqaView',
  components: { navigation },
  setup() {
    const { showQA } = func()

    return { showQA }
  }
}
</script>

<style scoped></style>
