<template>
  <navigation />
  <div class="section_qa">
    <ul class="qa_list">
      <li class="list_menu">
        <div class="list_menu_button">
          <img src="/static/images/icon/qa_account.png" class="menu_icon" alt="" />
          <div class="menu_txt">關於adiclub</div>
          <img src="/static/images/icon/qa_arrow.png" class="qa_arrow" alt="" />
        </div>
        <ul class="qa_detail_list">
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q1"> - 我要如何加入adiclub會員? </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q3">
              - 我要如何查詢我的會員等級、累積消費、優惠券?
            </router-link>
          </li>
        </ul>
      </li>
      <li class="list_menu">
        <div class="list_menu_button">
          <img src="/static/images/icon/qa_adidas.png" class="menu_icon" alt="" />
          <div class="menu_txt">關於累積消費</div>
          <img src="/static/images/icon/qa_arrow.png" class="qa_arrow" alt="" />
        </div>
        <ul class="qa_detail_list">
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q4">
              - 為何我在特定門市的消費沒有加入累計消費金額?
            </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q5">
              - 我的交易訂單何時會成為累計消費金額?
            </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q6">
              - 在會員中心的累積消費金額，為何沒有包含我全部的交易?
            </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q7">
              - 已達累積消費金額，何時可以升級會員?
            </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q8">
              - 門市與官網的消費金額是一起累計嗎?
            </router-link>
          </li>
        </ul>
      </li>
      <li class="list_menu">
        <div class="list_menu_button">
          <img src="/static/images/icon/qa_coupon.png" class="menu_icon" alt="" />
          <div class="menu_txt">關於優惠券</div>
          <img src="/static/images/icon/qa_arrow.png" class="qa_arrow" alt="" />
        </div>
        <ul class="qa_detail_list">
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q9">
              - 為何我在特定門市不能使用優惠券?
            </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q10">
              - 優惠券何時發送(購物優惠券/升級優惠券/生日優惠券)?
            </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q11"> - 我的優惠券為何無法使用? </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q12"> - 同個訂單可否使用多張優惠券? </router-link>
          </li>
          <li class="detail_list_item">
            <router-link to="/noqa?page=qa&item=Q13"> - 退貨時，優惠券會如何處理? </router-link>
          </li>
        </ul>
      </li>
      <li class="list_menu">
        <div class="list_menu_button">
          <img src="/static/images/icon/qa_refund.png" class="menu_icon" alt="" />
          <div class="menu_txt">其他</div>
          <img src="/static/images/icon/qa_arrow.png" class="qa_arrow" alt="" />
        </div>
        <ul class="qa_detail_list">
          <li class="detail_list_item">- <a :href="CONTACT_LINK">聯繫客服</a></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

import { CONTACT_LINK } from '@/constants'

import navigation from '../components/navigation.vue'
import func from '../js/func.js'

export default defineComponent({
  name: 'QaView',
  components: { navigation },
  setup() {
    const { qaClick } = func()
    onMounted(() => {
      qaClick()
    })

    return {
      CONTACT_LINK
    }
  }
})
</script>

<style scoped></style>
