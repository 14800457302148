<template>
  <div :class="['coupon', `type_${couponType}`, { online_only: item.IssueChannel === 100000001 }]">
    <div class="coupon_name" :class="`type_${couponType}`">
      {{ title }}
      <div v-if="item.isOnline">(限官網)</div>
      <div v-if="item.isOffline">(限直營門市)</div>
    </div>
    <div
      class="coupon_value"
      v-if="item.DiscountType == undefined || item.DiscountType == 100000000"
    >
      NT$
      <div class="coupon_value_num">
        {{ item.DiscountAmount }}
      </div>
    </div>
    <div class="coupon_value percentage" v-if="item.DiscountType == 100000001">
      <div class="coupon_value_num">{{ 100 - (item.DiscountAmount * 100).toFixed(1) }}</div>
      <span>% off</span>
    </div>
    <div class="coupon_info">
      <div class="coupon_info_date">{{ item.BeginTime }}-{{ item.EndTime }}</div>
      <img
        :src="item.barcodeImage"
        class="coupon_info_barcode"
        :alt="item.CouponId"
        @error="
          $event.target.src =
            'https://via.placeholder.com/853x236.png/FFFFFF/000000/?text=%20' + item.CouponId
        "
      />
      <div class="coupon_info_barNum">
        {{ item.CouponId }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed,defineComponent } from 'vue'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      couponType: computed(() => {
        if ([100000006, 100000007].includes(props.item.Type) && typeof props.item.ColorType === 'number') {
          return props.item.ColorType
        }

        return props.item.Type
      }),
      title: computed(() => {
        if (props.item.Name) {
          return props.item.Name
        }
        switch (props.item.Type) {
          case 100000000:
            return '入會優惠券'
          case 100000001:
            return '升级優惠券'
          case 100000002:
            return '續會優惠券'
          case 100000003:
            return '生日優惠券'
          case 100000004:
            return '購物優惠券'
          case 100000005:
            return '限時優惠券'
          case 100000006:
            return '活動優惠券'
        }
        return ''
      })
    }
  }
})
</script>

<style></style>
