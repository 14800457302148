<template>
  <navigation />
  <ul class="section_tab">
    <li class="tab_item active">未使用</li>
    <li class="tab_item">已失效</li>
  </ul>
  <div v-if="couponsLoader.loading">
    <Loading :height="`calc(100vh - 155px)`" />
  </div>
  <div v-if="couponsLoader.fulfill" class="section_coupon">
    <div class="coupon_list enable_coupon active">
      <div v-if="unusedCoupons.length === 0" class="empty_coupons">目前無優惠券紀錄</div>
      <CouponTicket
        v-for="item in unusedCoupons"
        :item="item"
        :key="item.CouponId"
        @click="couponClick(item)"
      />
    </div>
    <div class="coupon_list unable_coupon">
      <div v-if="usedCoupons.length === 0" class="empty_coupons">目前無優惠券紀錄</div>
      <CouponTicket v-for="item in usedCoupons" :item="item" :key="item.CouponId" />
    </div>
  </div>

  <div class="section_bottom_cta hide">
    <div class="coupon_cta" @click="showCouponRule">
      <span>查看優惠券使用規則與限制</span>
      <img src="/static/images/icon/cta_guide2.png" class="cta_guide" alt="arrow-right" />
    </div>
  </div>

  <div :class="{ popup_wrap: true, hide: isCouponRulePopoverHidden }">
    <div class="popup">
      <div class="popup_header">
        <div class="header_title">優惠券規則與限制</div>
        <img src="/static/images/icon/close.png" class="close" alt="close-icon" @click="closeTip" />
      </div>
      <ol class="couponRule_list">
        <li>
          優惠券僅限於有效期限內於該優惠券指定通路 (adidas直營門市<a
            href="https://www.adidas.com.tw/Article/Detail/82536"
            >https://www.adidas.com.tw/Article/Detail/82536</a
          >或adidas台灣官方購物網站<a href="https://adidas.com.tw">adidas.com.tw</a>)購買商品。
        </li>
        <li>
          所有adiclub所發送的會員專屬優惠券，包括但不限於購物優惠券、生日優惠券、升級優惠券或續會優惠券，不論單獨使用或疊加使用，其訂單之付款總額不得低於購買商品總訂價之50%。
        </li>
        <li>
          入會優惠券需於訂單商品總價需至少滿$2,000以上方可使用，且不得與其他adiclub優惠券同時使用。
        </li>
        <li>
          當優惠券與其它折扣併用時，實際結帳金額依消費通路(adidas直營門市或adidas官方網站)的計算方式為主。
        </li>
        <li>優惠券不適用於adidas部分指定商品，包含但不限於特定限量或聯名商品。</li>
        <li>優惠券僅可使用一次，使用後無法做任何取消或更正。</li>
        <li>優惠券不可與員工折扣或其他特定優惠活動同時使用。</li>
        <li>優惠券不能轉賣、轉讓、或贈送給第三人，亦不能折現或兌換其他權益。</li>
        <li>優惠券不論因遺失或任何原因均不會重發。</li>
        <li>
          具體優惠券使用規則及限制請參考
          <a href="https://www.adidas.com.tw/V2/MyAccount/VipMemberBenefits">《adiclub會員規範》</a
          >7-2項之「優惠券的定義和使用」。
        </li>
        <li>優惠券折抵後，每件商品的折扣金額將四捨五入至最接近之整數計算。</li>
        <li>
          官網鑑賞期內退貨或直營門市換貨時，使用優惠券折抵的金額將不會返還，使用的adiclub優惠券將不會以任何形式退還到會員帳號。換貨的差額不得使用優惠券。
        </li>
        <li>
          在adidas官網使用adiclub優惠券於同一訂單中購買多個商品，該筆訂單不接受部分商品退貨，僅接受整筆訂單所有商品同時退貨。
        </li>
        <li>
          其他adiclub活動優惠券使用條件及有效期，相關條件與本規範不一致的，以屆時公佈的資訊為準。
        </li>
        <li>台灣阿迪達斯股份有限公司(adidas)有權隨時更改上述條款，毋須另行通知。</li>
        <li>如有任何爭議，adidas將保留最終決定權。</li>
      </ol>
    </div>
  </div>

  <div :class="{ tipPopup_wrap: true, hide: isCouponPopoverHidden }">
    <div class="tipPopup">
      序號：{{ couponId }}<br />
      是否確認使用優惠券？<br />
      (請由門市人員操作)
      <div class="tipPopup_cta">
        <div class="tipPopup_cta_button sure_cta" @click="confirmUseCoupon">
          {{ updateLoading ? '優惠券確認中' : '核銷' }}
        </div>
        <a class="tipPopup_cta_button cancel_cta" @click="closeTip" v-if="!updateLoading"> 返回 </a>
      </div>
      <div style="overflow: scroll; text-align: left; max-height: 200px; margin-top: 20px">
        <ul class="coupon-consents">
          <li v-for="consent in couponConsents" :key="consent" v-html="consent" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import xss from 'xss'

import CouponTicket from '@/components/CouponTicket.vue'
import Loading from '@/components/Loading.vue'

import navigation from '../components/navigation.vue'
import useUpdateCoupons from '../composables/useUpdateCoupons'
import {
  COUPON_ISSUE_CHANNEL_OFFLINE_ONLY,
  COUPON_ISSUE_CHANNEL_ONLINE_ONLY,
  COUPON_STATE_EXPIRED,
  COUPON_STATE_PRE_USED,
  COUPON_STATE_UNUSED,
  COUPON_STATE_USED,
  COUPON_UPDATE_TYPE_PRE_USED,
  RESULT_CODE_SUCCESS,
  UI_DATE_FORMAT_ZH_TW
} from '../constants'
import func from '../js/func.js'
import { updateCoupon } from '../services'
import { formatDate, toBarcodeImage } from '../utils'

const isUnused = (coupon) => coupon.State === COUPON_STATE_UNUSED
const isUsed = (coupon) =>
  coupon.State === COUPON_STATE_USED ||
  coupon.State === COUPON_STATE_PRE_USED ||
  coupon.State === COUPON_STATE_EXPIRED
const isOnlineOnly = (coupon) => coupon.IssueChannel === COUPON_ISSUE_CHANNEL_ONLINE_ONLY
const isOfflineOnly = (coupon) => coupon.IssueChannel === COUPON_ISSUE_CHANNEL_OFFLINE_ONLY

const formatToUI = (coupon) => ({
  ...coupon,
  BeginTime: formatDate(coupon.BeginTime, UI_DATE_FORMAT_ZH_TW),
  EndTime: formatDate(coupon.EndTime, UI_DATE_FORMAT_ZH_TW),
  isOnline: isOnlineOnly(coupon),
  isOffline: isOfflineOnly(coupon),
  barcodeImage: toBarcodeImage(coupon.CouponId)
})

const URLMatcher =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim

export default {
  name: 'CouponView',
  components: { navigation, Loading, CouponTicket },
  data() {
    return {
      isCouponRulePopoverHidden: true
    }
  },
  setup() {
    const { tabClick } = func()
    const currentCoupon = ref(null)
    const updateLoading = ref(false)
    const isCouponPopoverHidden = ref(true)

    const useCoupon = (couponId) => {
      store.dispatch('useCoupon', { couponId })
    }

    const preUseCoupon = async (couponId) => {
      if (updateLoading.value) {
        return
      }

      updateLoading.value = true
      try {
        const result = await updateCoupon({
          couponId,
          updateType: COUPON_UPDATE_TYPE_PRE_USED
        })
        isCouponPopoverHidden.value = true
        updateLoading.value = false

        if (result.ResultCode === RESULT_CODE_SUCCESS) {
          useCoupon(couponId)
        } else {
          await Swal.fire({
            icon: 'error',
            title: '<h2 data-testid="errorTitle">發生錯誤</h2>',
            html: `<span data-testid="errorMsg">${result.ResultDesc}</span>`,
            confirmButtonColor: '#3e6be2',
            confirmButtonText: '我知道了'
          })
        }
      } catch (e) {
        alert(e.message)
      }
    }

    const store = useStore()
    const couponsLoader = computed(() => store.state.coupons)
    const usedCoupons = computed(() => {
      const data = store.state.coupons.data
      if (data === null) {
        return []
      }
      return (data.ListOfCoupons ?? []).filter(isUsed).map(formatToUI)
    })
    const unusedCoupons = computed(() => {
      const data = store.state.coupons.data
      if (data === null) {
        return []
      }
      return (data.ListOfCoupons ?? []).filter(isUnused).map(formatToUI)
    })

    useUpdateCoupons()

    onMounted(() => {
      tabClick()
    })

    return {
      couponsLoader,
      usedCoupons,
      unusedCoupons,
      currentCoupon,
      updateLoading,
      isCouponPopoverHidden,
      preUseCoupon,
      couponId: computed(() => (currentCoupon.value !== null ? currentCoupon.value.CouponId : '')),
      couponConsents: computed(() => {
        if (currentCoupon.value === null) {
          return ''
        }
        let consents = currentCoupon.value.Description.replace(
          URLMatcher,
          (match) => `<a href="${match}" target="__blank">${match}</a>`
        )
          .split('\n')
          .map(xss)
        return consents
      })
    }
  },
  methods: {
    confirmUseCoupon() {
      if (this.currentCoupon === null) return
      this.preUseCoupon(this.currentCoupon.CouponId)
    },
    showCouponRule() {
      this.isCouponRulePopoverHidden = false
    },
    couponClick(coupon) {
      if (coupon.IssueChannel === COUPON_ISSUE_CHANNEL_ONLINE_ONLY) return

      this.isCouponPopoverHidden = false
      this.currentCoupon = coupon
    },
    closeTip() {
      this.isCouponRulePopoverHidden = true
      this.isCouponPopoverHidden = true
      this.currentCoupon = null
    }
  }
}
</script>

<style scoped></style>
