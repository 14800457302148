<template>
  <div class="loader-container" :style="{ height: height }">
    <div class="flex-box center-box">
      <svg class="circular-loader" viewBox="25 25 50 50">
        <circle
          class="circular-loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="#00b900"
          stroke-width="2"
        />
      </svg>
      <p class="paragraph">前往頁面中，請稍候...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingView',
  props: {
    height: {
      type: String,
      default: '100vh'
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.loader-container {
  position: relative;
}

.font-medium {
  font-family: -apple-system, 'PingFangTC-Medium', 'Noto Sans', Arial, 'Source Han Sans TC',
    sans-serif;
  font-weight: bold;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.center-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.min-width-300 {
  min-width: 300px;
}

.headline {
  margin-top: 1.125rem;
  color: #4a4a4a;
  font-size: 1rem;
  font-weight: bold;
}

.paragraph {
  margin-top: 0.4375rem;
  color: #808080;
  font-size: 0.9375rem;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.circular-loader {
  width: 40px;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.circular-loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}
</style>
