import liff from "@line/liff";
import { createStore } from "vuex";

import { COUPON_STATE_PRE_USED } from "../constants";

export default createStore({
  state: {
    userTierExpiredModalOpen: false,
    user: {
      loading: true,
      fulfill: false,
      reject: false,
      data: null,
    },
    coupons: {
      loading: true,
      fulfill: false,
      data: null,
    },
    orders: {
      loading: true,
      fulfill: false,
      data: null,
    },
  },
  mutations: {
    setUserTierExpiredModal(state, { open }) {
      state.userTierExpiredModalOpen = open;
    },
    updateUser(state, { user }) {
      state.user = { ...state.user, ...user };
    },
    updateCoupons(state, { coupons }) {
      state.coupons = { ...state.coupons, ...coupons };
    },
    updateOrders(state, { orders }) {
      state.orders = { ...state.orders, ...orders };
    },
    useCoupon(state, { couponId }) {
      if (state.coupons.data !== null) {
        state.coupons.data.ListOfCoupons = state.coupons.data.ListOfCoupons.map(
          (coupon) => {
            if (coupon.CouponId === couponId) {
              return {
                ...coupon,
                State: COUPON_STATE_PRE_USED,
              };
            }
            return coupon;
          }
        );
      }
    },
  },
  actions: {
    updateUser({ commit }, { user }) {
      commit("updateUser", { user });
    },
    updateCoupons({ commit }, { coupons }) {
      commit("updateCoupons", { coupons });
    },
    useCoupon({ commit }, { couponId }) {
      commit("useCoupon", { couponId });
    },
    updateOrders({ commit }, { orders }) {
      commit("updateOrders", { orders });
    },
    closeWindow() {
      liff.closeWindow();
    },
    setUserTierExpiredModal({ commit }, open) {
      commit("setUserTierExpiredModal", { open });
    },
  },
});
