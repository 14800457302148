import liff from "@line/liff";
import { onMounted } from "vue";
import { useStore } from "vuex";

import { getCoupons,setupAuthentication } from "../services";

export default function useUpdateCoupons() {
  const store = useStore();

  const updateCoupons = (payload) => store.dispatch("updateCoupons", payload);

  onMounted(async () => {
    try {
      await liff.ready;
      await setupAuthentication();

      const data = await getCoupons();
      updateCoupons({ coupons: { data, loading: false, fulfill: true } });
    } catch (error) {
      updateCoupons({ coupons: { loading: false, reject: true } });
    }
  });
}
